<template>
    <div id="app">
        <div v-if="isMobile" class="mobile-warning">
            <el-alert
                title="警告"
                type="warning"
                :center="false"
                :closable="false"
                :show-icon="true">
                <div class="mobile-warning-content">
                    <p>感谢您对zCoud智能问答的支持！很抱歉，当前页面暂不支持移动端访问，请复制当前链接至 PC 端进行访问。</p>
                    <p>https://ai.zcloud.enmotech.com</p>
                </div>
            </el-alert>
        </div>
        <div v-else>
            <router-view />
        </div>
    </div>
</template>

<script>
export default {
    name: 'App',
    data() {
        return {
            isMobile: false,
            message: ''
        };
    },
    methods: {
        detectDevice() {
            const userAgent = navigator.userAgent || navigator.vendor || window.opera;
            this.isMobile = /Mobi|Android/i.test(userAgent);
            if (this.isMobile) {
                // 禁用交互
                document.addEventListener('touchstart', this.preventDefaults);
                document.addEventListener('click', this.preventDefaults);
            }
        },
        preventDefaults(event) {
            event.preventDefault();
            event.stopPropagation();
        },
    },
    created () {
        this.detectDevice();
    }
}
</script>

<style lang="scss">
html,
body {
    padding: 0;
    margin: 0;
}
#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
}

nav {
    padding: 30px;

    a {
        font-weight: bold;
        color: #2c3e50;

        &.router-link-exact-active {
            color: #42b983;
        }
    }
}

.mobile-warning {
    position: fixed;
    top: 50%;
    left: 0;
    width: 100%;
    transform: translateY(-50%);
}
.mobile-warning-content {
    text-align: left;
}
</style>
