import axios from 'axios'

const axiosInstance = axios.create({
  baseURL: '/api/v1/'
})

axiosInstance.interceptors.request.use(config => {
  config.headers['Authorization'] = `Bearer ${localStorage.getItem('token')}`
  return config
}, error => {
  return Promise.reject(error)
})

axiosInstance.interceptors.response.use(response => {
  return response
}, error => {
  if (error.response.status === 401) {
    localStorage.removeItem('token')
      localStorage.removeItem('userInfo')
      const params = window.location.href.split('?')[1]
    window.location.href = '/?'+params
  }
  return Promise.reject(error)
})

export default axiosInstance
